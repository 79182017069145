const $ = require('jquery');
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;
require('bootstrap');
require('@popperjs/core/lib/popper.js');
import 'simplelightbox/dist/simple-lightbox.min.js';

$(document).ready(function(){

    // To Scroll Top
    $("#oto-back-to-top").hide();
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $('#oto-back-to-top').fadeIn();
            } else {
                $('#oto-back-to-top').fadeOut();
            }
        });
        // scroll body to 0px on click
        $('#oto-back-to-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 100);
            return false;
        });
    });

    // LightBox
    if ($(".gallery").length) {
        $('.gallery').simpleLightbox();
    }

    let screenWidth = document.documentElement.clientWidth || window.innerWidth;
    if (screenWidth > 996) {
        document.querySelectorAll('.navbar .nav-item').forEach(function(everyItem){
            everyItem.addEventListener('click', function(e){
                let el_link = this.querySelector('a[data-bs-toggle]');
                if (el_link != null){
                    location.href = el_link.href;
                }
            });
        });
    }else{
        const clickedItems = document.querySelectorAll('.dropdown-toggle');
        for (let i = 0; i < clickedItems.length; i++) {
            window['clicked' + i] = 0;
            clickedItems[i].addEventListener('click', (e) => {
                window['clicked' + i]++;
                if (window['clicked' + i] > 1 && !e.target.classList.contains('show')) {
                    location.href = e.target.href
                    window['clicked' + i] = 0;
                }
            });
        }
    }

    /*
    const setWH = (image, origin) => {
        image.width = image.naturalWidth;
        image.height = image.naturalHeight;
        console.log(`${origin} => w: ${image.width}, h: ${image.height}`);
    };
    [...document.images]
        .forEach(image => {
            if (!image.complete) {
                return image.addEventListener(`load`, () => setWH(image, `load`));
            }
            return setWH(image, `complete`);
        });*/
});


